import * as React from 'react';
import {MapContainer, Marker, Popup, useMap, useMapEvent, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import mapPin from '../assets/mapPinNew.png';
import posIcon from '../assets/standort.png';
import L, {LatLng} from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import locateIcon from '../assets/mapIcon.png';
import barIcon from '../assets/bar.png';
import bayIcon from '../assets/bay.png';
import binocularsIcon from '../assets/binoculars.png';
import campingIcon from '../assets/camping.png';
import coffeeIcon from '../assets/coffee.png';
import hotelIcon from '../assets/hotel.png';
import parkingIcon from '../assets/parking.png';
import shoppingIcon from '../assets/shopping.png';
import restIcon from '../assets/food-restaurant-icon.png';
import searchIcon from '../assets/search.png';
import {buildOverpassApiUrl, getUrlForImage, HelperMiddleButton, ToggleSwitch} from "./Utils";
import {add_new_location, both_t, friends_t, lan_code, own_t, search_t, t} from "./languages";
import {getCookieConsentValue} from "react-cookie-consent";
import plusImage from "../assets/middlePlus.png";
import {BackendUrl, fetchDataFromExtUrl} from "./API";
import {ContentContext} from "./ContentScreen";

let start = 0;
let startZoom = 0;

function getRestIcon(poi){

  // eslint-disable-next-line default-case
  switch(poi){
    case "amenity=restaurant":
      return L.icon({
        iconUrl: restIcon,
        iconSize: 20
      });
    case "amenity=cafe":
      return L.icon({
        iconUrl: coffeeIcon,
        iconSize: 20
      });
    case "amenity=bar":
      return L.icon({
        iconUrl: barIcon,
        iconSize: 20
      });
    case "amenity=parking":
      return L.icon({
        iconUrl: parkingIcon,
        iconSize: 20
      });
    case "tourism=attraction":
      return L.icon({
        iconUrl: binocularsIcon,
        iconSize: 20
      });
    case "tourism=hotel":
      return L.icon({
        iconUrl: hotelIcon,
        iconSize: 20
      });
    case "tourism=camp_site":
      return L.icon({
        iconUrl: campingIcon,
        iconSize: 20
      });
    case "shop":
      return L.icon({
        iconUrl: shoppingIcon,
        iconSize: 20
      });
    case "natural=bay":
      return L.icon({
        iconUrl: bayIcon,
        iconSize: 20
      });
  }

}

function getPosIcon(){
  return L.icon({
    iconUrl: posIcon,
    iconSize: 20
  });
}

function getLocIcon(img, friend = false){
  if(friend){
    return L.icon({
      iconUrl: img,
      className: "resizeMapIcon shadowFriend",
      iconAnchor: [35, 35]
    });
  }
  else{
    return L.icon({
      iconUrl: img,
      className: "resizeMapIcon",
      iconAnchor: [35, 35]
    });
  }
}

function getPinIcon(){
  return L.icon({
    iconUrl: mapPin,
    iconSize: 60,
    iconAnchor: [30, 60]
  });
}

function SetView({curPos}){
  const map = useMap();

  useMemo(() => {

    if(localStorage.getItem("zoomMap") && localStorage.getItem("centerMap")){
      const centers = localStorage.getItem("centerMap").split(";");
      map.setView(new LatLng(centers[0], centers[1]), parseInt(localStorage.getItem("zoomMap")));
    }
    else{
      if(map.getCenter().lng === 0 && map.getCenter().lat === 0 && curPos !== [0, 0]){
        map.setView(curPos, map.getZoom());
        console.log(curPos);
      }
    }

    let myFilter = [
      'blur:0px',
      'brightness:100%',
      'contrast:100%',
      'grayscale:100%',
      'hue:300deg',
      'opacity:100%',
      'invert:0%',
      'saturate:100%',
      'sepia:0%'
    ];
    L.tileLayer.colorFilter('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        filter: myFilter
      }
    ).addTo(map);
  }, [map, curPos]);

  return null;

}

function Searchbar({setPin}){
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const map = useMap();

  useEffect(() => {
    if(searchValue.length > 1){
      setSearchResult([{"display_name": "..."}]);
      const timer = setTimeout(() => {
        const url = "https://nominatim.openstreetmap.org/search?format=json&limit=10&accept-language=" + t(lan_code) + "&q=" + searchValue;
        fetchDataFromExtUrl(url, setSearchResult);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [searchValue]);

  return (<div className="searchfieldOnMap">
    <input className="inputField" id="searchbar" onChange={(e) => setSearchValue(e.target.value)}
           placeholder={t(search_t)}/>
    {searchResult.map((element, index) => {
      return (
        <div className="autocomplete" id={"id-" + index}>
                    <span className="autocompleteText" key={"searchRID" + index} onClick={() => {
                      setSearchResult([]);
                      if(element.lat && element.lon){
                        setPin([element.lat, element.lon]);
                        map.flyTo([element.lat, element.lon]);
                      }
                    }}>
                        {element.display_name}
                    </span>
        </div>);
    })}
  </div>);

}

function MapInteraction({pinPos, setPinPos}){
  let mousedown = false;
  let dblClick = false;
  let dblClickOn = true;
  const map = useMap();

  map.doubleClickZoom.disable();
  //https://reactjs.org/docs/events.html#mouse-events
  useMapEvents({
    dblclick(mouse){
      if(dblClickOn){
        map.setZoomAround(mouse.latlng, map.getZoom() + 1);
      }
    },
    contextmenu(mouse){
      setPinPos([mouse.latlng.lat, mouse.latlng.lng]);
    },
    mouseup(){
      if(mousedown){
        dblClick = true;
      }
      if(dblClick){
        mousedown = false;
        dblClick = false;
      }

      mousedown = true;
      setTimeout(() => {
        mousedown = false;
      }, 200);
    },
    mousedown(mouse){
      if(mousedown && (start.originalEvent.screenY >= mouse.originalEvent.screenY - 7 && start.originalEvent.screenY <= mouse.originalEvent.screenY + 7)){
        dblClick = true;
        start = mouse;
        startZoom = map.getZoom();
      }
      start = mouse;
    },
    drag(mouse){
      dblClickOn = false;
      if(dblClick){
        map.setZoomAround(start.latlng, startZoom + Math.round((start.originalEvent.screenY - mouse.originalEvent.screenY) / 5) / 10);
      }
    },
    dragend(){
      setTimeout(() => {
        dblClickOn = true;
      }, 200);
      dblClick = false;
    },
    click(mouse){
      if(document.getElementById("searchbar") && mouse.containerPoint.y < 60){
        document.getElementById("searchbar").focus();
      }
    }
  });

  return pinPos === null ? null : (
    <Marker position={pinPos} icon={getPinIcon()}/>
  );

}

const createClusterCustomIcon = function (cluster){
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "locImg",
    iconSize: L.point(60, 60, true)
  });
};

function CenterViewButton({curPos, setShowSearch}){
  const map = useMap();

  if(curPos[0] && curPos[0]){
    return (
      <div className="leaflet-top leaflet-right">
        <img className="leaflet-control  iconSpaced bgDarkBlue" src={locateIcon} onClick={() => {
          map.flyTo(curPos);
          setShowSearch(0);
        }} alt={""}/>
      </div>
    );
  }
  else{
    return (
      <div className="leaflet-top leaflet-right">
        <img className="leaflet-control  iconSpaced bgDarkBlue" style={{backgroundColor: "gray", opacity: 0.8}}
             src={locateIcon} onClick={() => {
          setShowSearch(0);
        }} alt={""}/>
      </div>
    );
  }
}

function SaveViewport(){
  const map = useMap();

  useEffect(() => {
    const interval = setInterval(() => {
      if(getCookieConsentValue("acceptCookies") === "true"){
        localStorage.setItem("centerMap", map.getCenter().lat + ";" + map.getCenter().lng);
        localStorage.setItem("zoomMap", map.getZoom().toString());
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });

  return null;
}

function ShowLocations({data, category, setPin, setContent}){
  const map = useMap();
  return (
    <div>
      {data.my_locations && category !== t(friends_t) ? data.my_locations.map((location) => {
        return (<Marker position={[location.lat, location.lon]}
                        key={location.idlocation}
                        icon={getLocIcon(getUrlForImage(location["photo"].split(";")[0], location.idlocation))}
                        eventHandlers={{
                          click: () => {
                            if(getCookieConsentValue("acceptCookies") === "true"){
                              localStorage.setItem("centerMap", map.getCenter().lat + ";" + map.getCenter().lng);
                              localStorage.setItem("zoomMap", map.getZoom().toString());
                            }
                            setPin(location);
                            setContent(6);
                          }
                        }}
        />);
      }) : null}
      {data.locations_friends && category !== t(own_t) ? data.locations_friends.map((location) => {
        return (<Marker position={[location.lat, location.lon]}
                        key={location.idlocation}
                        icon={getLocIcon(BackendUrl + location["photo"].split(";")[0], true)}
                        eventHandlers={{
                          click: () => {
                            if(getCookieConsentValue("acceptCookies") === "true"){
                              localStorage.setItem("centerMap", map.getCenter().lat + ";" + map.getCenter().lng);
                              localStorage.setItem("zoomMap", map.getZoom().toString());
                            }
                            setPin(location);
                            setContent(6);
                          }
                        }}
        />);
      }) : null}
    </div>
  );
}

function OSMElements({showIcons, setContent, setPin}){
  const map = useMap();
  const [locations, setLocations] = useState([]);
  const [poi, setPoi] = useState("");
  const activateZoom = 12;

  function fetchPOIs(){
    if(map.getZoom() >= activateZoom && poi.length){
      fetch(buildOverpassApiUrl(map, poi))
        .then((e) => {
          e.text().then((e) => {
            let XMLParser = require('react-xml-parser');
            let data = new XMLParser().parseFromString(e);    // Assume xmlText contains the example XML
            let locs = [];
            data.children.map((loc) => {
              let location = {};
              if(!loc.name || loc.name !== "node"){
                return null;
              }

              loc.children.map((att) => {
                if(att.attributes.k === "name"){
                  location["name"] = att.attributes.v;
                }
                if(att.attributes.k.includes("website")){
                  location["website"] = att.attributes.v;
                }
                if(att.attributes.k.includes("phone")){
                  location["phone"] = att.attributes.v;
                }
                if(att.attributes.k === "wheelchair"){
                  location["wheelchair"] = att.attributes.v;
                }
                if(att.attributes.k === "cuisine"){
                  location["cuisine"] = att.attributes.v;
                }
                if(att.attributes.k === "opening_hours"){
                  location["opening_hours"] = att.attributes.v;
                }
                switch(poi){
                  case "amenity=restaurant":
                    location["category"] = "restaurant";
                    break;
                  case "amenity=cafe":
                    location["category"] = "cafe";
                    break;
                  case "amenity=bar":
                    location["category"] = "bar";
                    break;
                  case "amenity=parking":
                    location["category"] = "parking";
                    break;
                  case "tourism=attraction":
                    location["category"] = "sight";
                    break;
                  case "tourism=hotel":
                    location["category"] = "hotel";
                    break;
                  case "tourism=camp_site":
                    location["category"] = "camping";
                    break;
                  case "shop":
                    location["category"] = "shop";
                    break;
                  case "natural=bay":
                    location["category"] = "bay";
                    break;
                  default:
                    location["category"] = "";

                }

              });
              location["lat"] = loc.attributes.lat;
              location["lon"] = loc.attributes.lon;
              location["id"] = loc.attributes.id;
              if(location.name){
                locs = [...locs, location];
              }
            });
            setLocations(locs);
          });
        });
    }
    else{
      setLocations([]);
    }
  }

  useEffect(() => {
    fetchPOIs();
  }, [poi]);

  useMapEvent({
    moveend(){
      fetchPOIs();
    }
  });

  return (<div>
    {showIcons && map.getZoom() >= activateZoom ?
      <div className="poiIcons" style={{marginTop: 80}}>
        <img className="leaflet-control  poiIcon" src={restIcon}
             onClick={() => {
               setPoi("amenity=restaurant");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={hotelIcon}
             onClick={() => {
               setPoi("tourism=hotel");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={campingIcon}
             onClick={() => {
               setPoi("tourism=camp_site");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={shoppingIcon}
             onClick={() => {
               setPoi("shop");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={coffeeIcon}
             onClick={() => {
               setPoi("amenity=cafe");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={barIcon}
             onClick={() => {
               setPoi("amenity=bar");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={parkingIcon}
             onClick={() => {
               setPoi("amenity=parking");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={bayIcon}
             onClick={() => {
               setPoi("natural=bay");
             }} alt={""}/>
        <img className="leaflet-control  poiIcon" src={binocularsIcon}
             onClick={() => {
               setPoi("tourism=attraction");
             }} alt={""}/>
      </div>
      : null}
    {locations.map((loc) => {
      return (
        <Marker position={[loc.lat, loc.lon]}
                key={loc.id}
                icon={getRestIcon(poi)}
                popUp={"as"}
                eventHandlers={{
                  click: () => {
                  }
                }}
        >
          <Popup>
            {loc.name}

            {loc.cuisine ? <br/> : null}
            {loc.cuisine ? loc.cuisine : null}


            {loc.opening_hours ? <br/> : null}
            {loc.opening_hours ? "opening: " + loc.opening_hours : null}

            {loc.phone ? <br/> : null}
            {loc.phone ? loc.phone : null}

            {loc.website ? <br/> : null}
            {loc.website ? loc.website : null}

            {loc.wheelchair ? <br/> : null}
            {loc.wheelchair && loc.wheelchair === "yes" ? "Barrierefrei" : null}
            <div className="button" style={{width: 25, height: 25}}>
              <img className="img" src={plusImage} onClick={() => {
                setPin([loc.lat, loc.lon, loc]);
                setContent(5);
              }} alt={""} style={{width: 25, height: 25}}/>
            </div>
          </Popup>
        </Marker>
      );
    })}
  </div>);
}

export default function Map({data, submit, setPin}){
  const [pinPos, setPinPos] = useState(null);
  const [category, setCategory] = useState(localStorage.getItem("mapDefault") ? localStorage.getItem("mapDefault") : t(both_t));
  const initZoom = localStorage.getItem("zoomMap") ? localStorage.getItem("zoomMap") : 15;
  const initCenter = localStorage.getItem("centerMap") ? localStorage.getItem("centerMap").split(";") : [48.80269135, 9.528841449999998];
  const categories = [t(own_t), t(friends_t), t(both_t)];
  const [showSearch, setShowSearch] = useState(0);
  const setContent = useContext(ContentContext)
  console.log("render map");

  const addLocation = useCallback(() => {
    setPin(pinPos);
    setContent(5);
  }, [pinPos, setPin, setContent]);

  useEffect(() => {
    submit.current = addLocation;
    setShowSearch(0);
  }, [addLocation, submit]);
  return (
    <div className="unselectable">
      <MapContainer
        center={initCenter}
        zoom={initZoom}
        style={{height: '100vh', width: '100wh'}}
        attributionControl={false}
        id="map"
        zoomSnap={0}
      >
        <SetView curPos={data.curPos}/>
        <MapInteraction pinPos={pinPos} setPinPos={setPinPos}/>
        <Marker position={data.curPos} icon={getPosIcon()}/>
        <CenterViewButton curPos={data.curPos} setShowSearch={setShowSearch}/>
        <SaveViewport/>

        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          <ShowLocations data={data} category={category} setPin={setPin}
                         setContent={setContent}/>
        </MarkerClusterGroup>

        {showSearch ?
          <Searchbar className="toggleSwitchOnMap" setPin={setPinPos}/>
          :
          <div>
            <ToggleSwitch className="toggleSwitchOnMap" categories={categories} setCategory={setCategory}
                          category={category}/>
            <div className="leaflet-top leaflet-right">
              <img className="leaflet-control  iconSpaced bgDarkBlue" style={{marginTop: 80}} src={searchIcon}
                   onClick={() => {
                     setShowSearch(1);
                   }} alt={""}/>
            </div>
          </div>
        }

        <OSMElements showIcons={showSearch} setContent={setContent} setPin={setPin}/>
      </MapContainer>

      <HelperMiddleButton text={t(add_new_location)}/>

    </div>
  );
}
